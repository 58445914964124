$baseMobileWidth: 1em;
$baseBorderColor: #cccccc;
$errorColor: red;

@mixin mobile-sticky-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1002;
  width: 100%;
  background: $color-white;
  padding: 20px;
}

@mixin mobile-checkout-back {
  font-size: 39px;
  position: absolute;
  left: 12px;
  top: 10px;
}

@mixin mobile-checkout-title {
  font-family: $brandon-text-bold;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-indent: 20px;
  margin-top: 6%;
  padding-bottom: 0;
  line-height: 50px;
  background: $color-gray-background;
  border-bottom: 1px solid $color-gray-border;
  border-top: 1px solid $color-gray-border;
}

@mixin mobile-offer-uparrow {
  background: url('/media/images/global/icon-dropdown.png') no-repeat center right 10px $color-gray-background;
}

@mixin mobile-offer-downarrow {
  background: url('/media/images/global/icon-dragup.png') no-repeat center right 10px $color-gray-background;
}

.shipping.checkout--single-page .checkout-progress .shipping,
.review.checkout--single-page .checkout-progress .review,
.confirm.checkout--single-page .checkout-progress .confirm {
  font-weight: bold;
}

.checkout {
  .loading {
    position: fixed;
  }
  margin-left: 20px;
  margin-right: 20px;
  #offer-code-panel {
    height: auto;
    padding-bottom: 0px;
    margin: 10px 0 20px 0;
    padding: 20px;
    input[type='submit'] {
      margin-top: 20px;
    }
    .checkout {
      &__panel-title {
        padding-bottom: 4px;
      }
    }
    .offer-code__byline {
      display: block;
      padding-bottom: 3%;
    }
    .offer-code-panel {
      &__content {
        margin-top: 5px;
      }
    }
  }
  #mobile-breadcrumb {
    display: block;
    padding: 10px 0px 15px 0px;
    ol.checkout-progress {
      li {
        float: left;
        list-style-type: decimal;
        width: 25% !important;
        margin: 0 10% 0 7% !important;
        line-height: 20px;
        padding: 5px 0px 5px 0px;
      }
    }
  }
  .checkout__content {
    clear: both;
    .viewcart-panel__content {
      .cart-items.products {
        .cart-item {
          .error {
            color: $color-red;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
  .mobile-breadcrumb li {
    display: list-item;
  }
  .checkout__panel-content {
    .sub__total {
      float: right;
      margin-top: 10px;
    }
    .cart-item__desc-container {
      .cart-item__qty {
        width: 60%;
        .cart-item__qty-label {
          label {
            float: left;
            margin-right: 10px;
          }
          .qty_select {
            width: auto;
          }
        }
      }
    }
  }
  .cart-item {
    .name a,
    .sub-name a {
      text-transform: uppercase;
    }
  }
  .address_controls {
    margin: 10px 0;
  }
  .payment_method_container {
    div.fs {
      margin-bottom: 15px;
      .credit_card,
      .paypal,
      .after_pay {
        margin-bottom: 12px;
      }
    }
  }
  .afterpayinfo {
    text-align: justify;
    font-size: 12px;
    padding-top: 15px;
    #afterpay_info .js-afterpay-learnmore-mobile {
      display: inline-block !important;
    }
  }
  #confirmation-number {
    margin: 10px 0;
  }
  #confirmation-email {
    margin-bottom: 10px;
  }
  .cart-item__swatch {
    margin-left: 0;
  }
  #continue-checkout-btn-element {
    width: 100%;
  }
  .return-link-container {
    width: 48%;
    float: right;
  }
  .print-buttons {
    width: 48%;
    float: left;
  }
  #confirmation-page {
    padding-top: 10px;
  }
  .recommended-products-panel {
    display: block;
    .recommended-item {
      .product_name a,
      .product_subname a,
      .cart-item__size,
      .shade,
      .formatted_price {
        font-size: 10px;
      }
      input[type='submit'] {
        padding: 0 5px;
        letter-spacing: normal;
        font-size: 10px;
      }
    }
    .recommended-item:last-child {
      padding-right: 0 !important;
    }
    .recommended-products-panel__title:after,
    .recommended-products-panel__title:before {
      width: 47px;
    }
  }
  .cross_sell_container {
    .cart_cross_sell_item {
      float: left;
      width: 30%;
      box-sizing: border-box;
      padding-right: 2em;
      margin-bottom: 20px;
      margin-right: 2%;
      .cart-item__desc-info {
        .color {
          margin: 14px 0 14px 0;
        }
      }
      .formatted_price {
        margin-bottom: 10px;
      }
    }
  }
  .order_status {
    header {
      display: block;
    }
  }
  .cart-items {
    border-bottom: 1px solid $baseBorderColor;
    .cart-item__qty {
      label {
        display: block;
        font-size: 14px;
      }
      form {
        width: auto;
      }
    }
    .cart-item {
      .qty_select {
        width: 5.25em;
      }
      .border {
        border: 1px solid #ffffff;
      }
    }
    .sub__total {
      margin: 0.8em 0;
      float: right;
    }
    .free_sample {
      font-weight: normal;
      text-transform: capitalize;
    }
  }
  .continue-buttons {
    .disabled {
      background-color: #ccc;
      pointer-events: none;
      cursor: default;
    }
    a.disabled:hover {
      background-color: #ccc;
    }
  }
  .cart-item {
    .error {
      color: $errorColor;
    }
  }
  .gift_form_container {
    margin-left: 20px;
    input[type='text'],
    textarea {
      &[data-error] {
        border-color: $errorColor;
      }
    }
    textarea {
      padding: 10px;
    }
  }
  .gift-options {
    input[type='text'],
    textarea {
      &[data-error] {
        border-color: $color-red;
      }
    }
  }
  .shipping-panel {
    .shipping-address {
      .shipping-edit-address-content__content {
        select {
          margin-bottom: 20px;
        }
      }
    }
    .billing-address {
      .billing-edit-content {
        select {
          margin-bottom: 20px;
        }
      }
    }
    &.finished {
      header.edit-controls {
        h2 + a.edit {
          margin-top: -12%;
          z-index: 10;
          right: 0px;
          bottom: 15px;
          text-transform: uppercase;
          padding-left: -20%;
          letter-spacing: 0.15em;
          cursor: pointer;
          font-weight: 700;
          padding: 2px 20px;
          float: right;
        }
      }
    }
  }
  .right.checkout__sidebar {
    #order-summary-panel {
      #links-panel {
        .content {
          .links_list {
            li {
              border: none;
            }
          }
        }
      }
    }
  }
  .required-text {
    margin-bottom: 10px;
  }
  .name-fields {
    .title {
      width: 100%;
    }
    .first-name,
    .last-name {
      margin-bottom: 20px;
    }
  }
  .qas {
    .qas_container {
      margin-bottom: 20px;
      width: 100%;
      label {
        opacity: 1 !important;
      }
    }
  }
  .field-container {
    .address1,
    .address2,
    .phone-1,
    .phone-2 {
      margin-bottom: 20px;
      width: 100%;
    }
    .card-message-to,
    .card-message-from {
      margin-bottom: 20px;
      width: 100%;
      padding-top: 3%;
    }
  }
  .city-state-zip {
    .form-item {
      &.state {
        padding-right: 4%;
      }
      &.state,
      &.city,
      &.postal-code {
        margin-bottom: 20px;
      }
    }
  }
  input[name='qas_search'] {
    height: 1em;
    padding: 20px 15px;
  }
  input[type='text'],
  input[type='password'],
  input[type='tel'],
  input[type='number'] {
    margin-bottom: 0;
  }
  .gift-cards {
    .gift-card-number,
    .gift-card-pin {
      margin-bottom: 20px;
      .invalid_marker {
        display: none;
      }
    }
    .gift-card-entry-container {
      padding-top: 5%;
    }
  }
  &.active-panel {
    &-shipping {
      #order-summary-panel {
        display: block;
      }
      .sign-in-panel {
        display: block;
      }
    }
    &-signin {
      #order-summary-panel {
        display: none;
      }
    }
  }
  &.active-panel-signin {
    .checkout {
      &__sidebar {
        display: none;
      }
    }
  }
  &.active-panel-shipping {
    .site-header {
      z-index: auto;
    }
  }
}

.touch {
  select {
    height: calc(3em - -2px);
  }
}

body {
  &.checkout {
    margin-left: 0;
    margin-right: 0;
  }
}

.shipping-edit-address-content__content {
  label {
    display: inline;
  }
  #address_form_container {
    border: none;
    padding: 0;
  }
}

#shipping-panel {
  .gift-options-display,
  .shipping-address-display {
    margin: 20px 0px 20px 0px;
  }
  a.btn-mini {
    top: 0;
    right: 0;
  }
}

.ui-autocomplete {
  overflow-x: auto;
  height: 13em;
}

ul.error {
  color: $errorColor;
}

.order-confirmation {
  a.order-details-page__link {
    display: none;
  }
}

.order-info {
  p {
    margin-bottom: 10px;
  }
}

.order-details {
  div.order-details__item {
    margin-bottom: 15px;
  }
}

.trans_detail_item {
  margin-bottom: 1.4em;
}

.samples-buttons,
.sample-select-checkbox {
  display: block;
}

#viewcart {
  .main_container {
    section {
      .checkout__sidebar {
        .content {
          .link {
            border: 0px;
          }
        }
      }
    }
  }
}

#shipping-panel {
  .gift_message {
    word-wrap: break-word;
  }
}

.confirm {
  #confirmation-page {
    .print-buttons,
    .return-link-container {
      width: 100%;
    }
    .form-item {
      &.password-hint {
        display: none;
      }
      &.password-description {
        margin: 5px 0 15px 0;
        display: inline-block;
      }
    }
  }
}

#review {
  #delivery-address-h {
    margin-top: 5px;
  }
}

.viewcart {
  #sticky-checkout-button {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    background: $color-white;
    z-index: 1002;
    height: auto;
    padding: 20px;
    a {
      background-color: $color-pink;
      width: 100%;
      &.disabled-button {
        background: $color-light-gray;
        pointer-events: none;
      }
    }
  }
  .single-message {
    color: $color-pink;
  }
  .sticky-checkout-button {
    background: $color-white;
    z-index: 1002;
    height: auto;
    padding: 10px 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    a {
      height: 44px;
      line-height: 44px;
      vertical-align: middle;
      padding: 0;
      border: 0;
      background-color: none;
      width: 100%;
      img {
        vertical-align: top;
        padding-top: 9px;
        margin-left: 5px;
        width: auto;
      }
    }
    .continue-shopping {
      display: none;
    }
    a.paypal-checkout {
      background: $color-white;
      border: 1px solid $color-black;
      vertical-align: top;
      margin: 4px 0px;
    }
  }
  .overlay-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-black;
    opacity: 0.5;
    z-index: 1000;
  }
  section.viewcart-buttons-panel {
    margin: 0;
  }
  header.checkout-header {
    .continue-shopping {
      display: none;
    }
    #top-viewcart-buttons {
      .continue-buttons {
        display: none;
      }
    }
    .checkout-header {
      &__title {
        background-color: $color-white;
        padding: 10px 15px;
        text-indent: 0;
        font-size: 21px;
        text-align: left;
        line-height: 22px;
        font-family: $brandon-text-bold;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        border-bottom: 1px solid $color-black;
      }
    }
  }
  section#viewcart-panel {
    #top-viewcart-buttons {
      display: none;
    }
    .viewcart-panel {
      &__content {
        .cart-item__total {
          display: block;
          float: right;
        }
      }
    }
    .cart-item {
      .color {
        float: left;
      }
      &__add-to-favorites {
        display: none;
      }
      .product {
        &__thumb {
          .cart-item {
            &__thumb-image {
              width: 100%;
            }
          }
        }
      }
      .replenishment {
        padding: 0px;
        clear: none;
        margin-top: 7%;
        width: 62%;
        float: right;
        .replenishment {
          &__description {
            font-size: 10px;
            float: right;
          }
        }
      }
      .cart-item {
        &__remove-form {
          a {
            margin-top: 25px;
            float: left;
            clear: both;
            width: 19%;
          }
        }
      }
      .size {
        display: block;
        .size-label {
          float: left;
        }
      }
    }
  }
  section#order-summary-panel {
    .order-summary {
      &__content {
        .order-summary {
          &__shipping-label,
          &__shipping-value {
            line-height: 60px;
          }
        }
      }
    }
  }
  .offer-section {
    .offer-code-panel {
      &__title {
        @include mobile-offer-uparrow;
      }
    }
    .active {
      .offer-code-panel {
        &__title {
          @include mobile-offer-downarrow;
        }
      }
    }
  }
  section#guarantee-panel {
    display: none;
  }
  .checkout {
    &__sidebar {
      border-top: 1px solid $color-black;
      .tab-switcher {
        display: none;
      }
    }
  }
  section#links-panel {
    li {
      border: none;
    }
  }
  .site-footer {
    margin-bottom: 2em;
  }
  // BOGO OfferCode Styles
  .bogo-offer {
    .cart-item {
      &__replenishment,
      &__remove-form,
      &__add-to-favorites {
        display: none;
      }
    }
  }
}

section.checkout {
  &__index-content {
    input[type='text'],
    input[type='password'],
    input[type='tel'],
    input[type='number'] {
      margin-bottom: 0;
    }
    input[type='email'] + label {
      display: block;
      height: 3em;
      line-height: 0em;
      margin-top: calc(-3em - 7px);
      cursor: text;
      float: left;
    }
    textarea + label {
      font-size: 14px;
    }
    .adpl {
      label {
        display: block !important;
        visibility: visible;
      }
    }
    #links-panel {
      display: none;
    }
    .checkout {
      &__sidebar {
        section#links-panel {
          .checkout__panel-title {
            @include mobile-checkout-title;
          }
          display: block;
        }
        section#need-help-panel {
          .checkout {
            &__panel-title {
              @include mobile-checkout-title;
            }
          }
          .content {
            .link {
              border: none;
              text-transform: none;
              font-family: $brandon-text;
              pointer-events: none;
            }
          }
        }
        section#shopping-bag-panel {
          .checkout__panel-title {
            @include mobile-checkout-title;
          }
          .item-count {
            float: right;
            margin-top: -13%;
            clear: both;
            opacity: 0.8;
            padding-right: 4%;
            text-transform: uppercase;
          }
        }
        section#viewcart-panel {
          .products {
            &.product-list {
              min-height: 0px;
            }
            .total {
              top: 50px;
            }
            .color {
              padding-top: 7%;
              .swatch {
                margin-top: -9%;
              }
            }
            .replenishment {
              padding-top: 5%;
            }
          }
        }
        .offer-section {
          .offer-code-panel {
            &__title {
              @include mobile-offer-uparrow;
            }
          }
          .active {
            .offer-code-panel {
              &__title {
                @include mobile-offer-downarrow;
              }
            }
          }
        }
        section#guarantee-panel {
          display: none;
        }
        &.display-footer {
          display: none;
          .offer-section {
            .offer-code-panel {
              &__title {
                @include mobile-offer-uparrow;
              }
            }
            .active {
              .offer-code-panel {
                &__title {
                  @include mobile-offer-downarrow;
                }
              }
            }
          }
        }
        .links-panel {
          .links_list {
            .link {
              border: none;
            }
          }
        }
      }
      &__content {
        .adpl {
          .invalid_marker {
            display: none;
          }
        }
        section#shipping-panel {
          &.collapsed {
            display: none;
          }
          &.active {
            .continue-button-wrapper {
              @include mobile-sticky-btn;
            }
          }
          .shipping-address {
            .default-shipping {
              .label-content {
                display: initial;
              }
              label {
                margin: 0 0 10% 0;
              }
            }
          }
        }
      }
    }
  }
}

.viewcart,
.samples,
.checkout,
.confirm {
  .messages {
    color: $color-pink;
  }
  header.site-header ul.site-utils__menu {
    display: none;
  }
  .site-footer {
    .site-footer__main {
      display: none !important;
    }
    .site-footer-contact {
      display: none !important;
    }
    .sticky-offer {
      display: none !important;
    }
  }
  .block-template-basic-responsive-v1 {
    background-color: $color-white;
  }
  .customer-service-section {
    div {
      display: block;
    }
  }
}

section.samples-page {
  section#samples-panel {
    &.active {
      .samples-buttons {
        display: block;
        .continue-button-wrapper {
          @include mobile-sticky-btn;
          input[type='submit'].btn {
            padding: 15px;
          }
        }
      }
      .remaining {
        .available_mobile {
          &.hidden {
            margin-bottom: -37%;
          }
        }
      }
    }
  }
  section.viewcart-buttons-panel {
    display: none;
  }
}

#index {
  .site-header {
    .mobile-checkout-back {
      display: block;
      .icon {
        @include mobile-checkout-back;
      }
    }
  }
  &.checkout {
    section {
      &.panel {
        position: initial;
        &.payment-panel,
        &.registration-panel,
        &.shipping-panel {
          display: none;
          position: relative;
        }
      }
    }
  }
}
