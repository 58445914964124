.spp-mobile-page {
  .product--full {
    .product__details {
      .product__actions {
        padding: 38px 0 48px;
      }
    }
  }
}

.site-footer-contact {
  .site-footer-contact__item.customerservice_width {
    width: 100%;
  }
  @media (min-width: 768px) {
    .site-footer-contact__item.customerservice_width {
      width: 50%;
      a {
        margin-left: 25%;
      }
    }
  }
}

.tiny-waitlist-overlay {
  .email_label {
    margin-bottom: 5px;
  }
  .email_input {
    display: inline-block;
    input[type='text'] {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .waitlist-header {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }
  h2 {
    margin: 0 0 15px 0;
    text-align: left;
  }
  p.waitlist_message {
    margin-bottom: 10px;
  }
  .error_messages.error li {
    margin-left: 0px;
  }
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
}

.waitlist_thankyou_message {
  font-family: $brandon-text-bold;
  margin: 90px 25px;
  line-height: 1.45em;
}

.waitlist-iframe-wrapper {
  height: 95%;
  width: 100%;
}

.site-footer__main {
  position: relative;
  border-bottom: 1px solid #cccccc;
}

.site-footer__column:nth-child(4) {
  position: absolute;
  bottom: 8px;
  right: 0px;
}

.site-footer__column {
  .country-select {
    padding-left: 20px;
  }
  .country-select__selectbox {
    width: 60%;
  }
}

.content-block {
  &--mpp-tout {
    .content-block {
      &--default {
        .position {
          &--bare {
            .content-block {
              &__picture {
                img {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-grid {
  &__item {
    &--promo {
      padding-bottom: 0;
    }
    &--slide {
      .product-brief {
        &__image {
          &-wrapper {
            width: auto;
          }
        }
      }
    }
  }
}

.lp_buttons_area {
  button {
    &:hover {
      background: $color-black !important;
    }
  }
}

.product-full-shades-flyout {
  .product-full-shades-flyout {
    &__content {
      height: 88%;
    }
  }
}
