#customer_service {
  .customer-service-header {
    width: 100%;
  }
  #serlist {
    margin: 20px;
    li div {
      margin-bottom: 10px;
    }
  }
}

.section-customer-service {
  .customer-service-landing-block {
    .customer-service-landing-block__link {
      bottom: 25px;
    }
    .customer-service-landing-block__content {
      margin-bottom: 0px;
      padding-bottom: 30px;
    }
  }
}

.section-customer-service-contact-us {
  .customer-service-contact-form__category {
    letter-spacing: 1px;
  }
}

.popup-offer {
  &__outer-wrap {
    height: 400px;
  }
}

@media (max-width: 1024px) {
  .popup-offer__outer-wrap {
    padding: 30px 0 0 0;
  }
}

#search {
  .pagination-container {
    .pagination {
      a {
        margin-left: 10px;
      }
    }
  }
}

.spp-mobile-page {
  .product {
    &__header {
      padding-bottom: 5px;
      .product__price {
        line-height: 10px;
      }
      .mobile-price {
        width: 35%;
        margin: 0px;
        .product_installment-price {
          font-size: 7px;
          letter-spacing: 0;
          line-height: 0;
          text-align: center;
          margin-top: 10px;
        }
        .afterpay_logo {
          padding-bottom: 5px;
          background: url(/media/export/cms/afterpay/ap-logo.png) no-repeat;
          height: 30px;
          width: 50px;
          display: inline-block;
          background-size: 100%;
          vertical-align: middle;
          position: relative;
          top: 10px;
        }
        a {
          text-transform: uppercase;
          color: #000;
        }
        &.discount_price {
          .price {
            text-align: inherit;
            display: inline;
          }
        }
        .price {
          text-align: center;
          display: block;
        }
      }
    }
    &__title {
      h2 {
        font-size: 1.17em;
      }
    }
    &__details {
      .product-replenishment-select {
        .purchase-one-time {
          padding-bottom: 3%;
          float: left;
        }
        .purchase-replenishment {
          .replenishment-label {
            float: left;
          }
          .og-deliver-text {
            padding: 3% 0;
            clear: both;
          }
        }
      }
    }
  }
  .how-to-use {
    &__content {
      .disclaimer {
        font-style: italic;
        text-align: center;
        padding-top: 0.75em;
        display: block;
      }
    }
  }
}

.site-footer {
  .afterpay-content,
  .afterpay-content-mb {
    display: none;
  }
  &__wrap {
    .site-footer-bottom:nth-child(2) {
      .site-footer-bottom__trademark {
        width: 90%;
      }
    }
  }
}
/*  Power reviews  */

.write_a_review__container {
  #pr-write {
    #pr-war-form {
      .pr-describeyourself-form-group {
        display: none;
      }
    }
  }
}
/* BIS */

.waitlist_thankyou_message {
  &.bis-mobile {
    font-family: $brandon-text;
  }
}

.p-w-r {
  .pr-star-v4,
  .pr-header-table,
  .pr-rating-stars,
  .pr-war,
  .pr-table-cell,
  .pr-header-title,
  span {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .promos {
      .free_ship {
        height: auto;
      }
    }
  }
}

.afterpay-content-mb {
  #afterpay_table {
    width: 299px;
    height: 1078px;
    border: 0;
    text-align: center;
    border-spacing: 5px;
  }
  .afterpay_mb_first {
    width: 299px;
    height: 337px;
  }
  .afterpay_mb_second {
    width: 299px;
    height: 584px;
  }
  .afterpay_mb_third {
    width: 299px;
    height: 157px;
  }
}

header.site-header {
  .site-logo {
    &__icon {
      font-size: 18px;
    }
  }
}

.lp_mobile {
  #lpChat {
    .lp_pagination-area {
      .lp_next_button,
      .lp_submit_button {
        background-color: $color-black !important;
      }
    }
  }
}

.product-full {
  &__data {
    .product-full-price {
      &__afterpay-installment-price {
        padding: 0 20px;
        .afterpay_logo {
          vertical-align: middle;
          min-width: 100px;
          min-height: 25px;
          position: absolute;
          margin-left: 5px;
        }
        #ap-learnmore {
          margin-left: 112px;
          position: absolute;
        }
      }
    }
  }
}

.product-vto__price {
  .product-full-price {
    &__afterpay-installment-price {
      display: none;
    }
  }
}
