.account {
  .inner {
    background: none repeat scroll 0 0 $color-white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 9px;
  }
}

#past_purchases {
  .past-purchases__content {
    margin: 40px 0;
    .past-purchases-page__header-container {
      text-align: center;
    }
    .outer-wrap {
      margin: 0 10px;
      .past-purchases__shopping {
        margin: 30px 0 0 0;
      }
    }
    .product-info {
      &__button {
        .product-info__link {
          display: block;
        }
      }
    }
  }
}

#password_reset {
  .password-reset-page {
    padding: 30px;
  }
  .password-reset__item {
    margin-bottom: 0.8em;
  }
  .password-request-page {
    padding: 30px;
  }
  .password-sent-page {
    padding: 30px;
  }
}

.main {
  .sign-in-page {
    padding-left: 10px;
  }
}

#registration {
  .profile-page {
    #profile_preferences {
      section.optional-info {
        .optional-info__fieldset {
          padding-left: 10px;
          select.error {
            border: 1px solid $color-red;
          }
        }
      }
      .profile-info__required {
        display: inline;
      }
    }
    .profile-page__content {
      margin-top: 40px;
      .profile-page__head {
        .profile-page__header {
          span {
            background: $color-white;
            display: inline-block;
            position: relative;
            padding: 0 7px 0 9px;
          }
        }
      }
      #profile_preferences {
        section {
          padding: 10px;
        }
      }
    }
  }
}
/* sign in starts */
#signin {
  input[type='submit'].sign-in-page__submit,
  input[type='button'].sign-in-page__submit {
    margin-bottom: 0px;
  }
  .account__return-user {
    .return-user__text {
      margin-top: 20px;
    }
  }
  .account__new-account,
  .account__return-user {
    display: block;
  }
}
/* sign in ends */

.main_container {
  section {
    .address-form__item {
      .grey_button {
        position: relative;
        top: 12px;
      }
    }
  }
}

.order-details-page__content {
  .order-products-summary {
    .cart-item__qty {
      width: 60%;
      .cart-item__qty-label {
        float: left;
        margin-right: 10px;
      }
    }
    .cart-item__total {
      float: left;
      margin-top: 10px;
    }
  }
}

.password-sent-page {
  .password-sent-page__content {
    .sent-info {
      .sent-info__text {
        margin-bottom: 10px;
        a {
          margin-top: 10px;
        }
      }
    }
  }
}

.shipping-billing {
  section.address-form {
    .address-form {
      &__header {
        font-size: 25px;
      }
    }
    #address {
      .address {
        .address-form__item {
          border: none;
          ul.error {
            border: none;
          }
        }
      }
    }
    .default_ship_container {
      .label-content {
        display: inline-block;
      }
    }
  }
}

#migrated-user {
  #container {
    #content {
      #migrated_user_conversion {
        input[type='radio'] {
          position: static;
          vertical-align: middle !important;
        }
      }
    }
  }
}

.sign-in-page {
  input[type='email'] {
    width: 100%;
  }
}

#replenish {
  div.replenishment-page {
    margin-top: 20px;
    .replenishment-page__header {
      letter-spacing: 1.5px;
    }
  }
}

.past-purchases-page {
  .account-utilities {
    display: none;
  }
  .past-purchases {
    &__content {
      .order-products {
        .cart-item {
          &__total {
            float: right;
            margin-top: 10px;
          }
          &__qty {
            .cart-item__qty-label {
              float: left;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

#payment_info {
  .account-utilities {
    display: none;
  }
  .account-payment-info-page {
    &__content {
      margin-left: 7%;
      &_header {
        margin-bottom: 20px;
      }
      .add-address {
        &.button-right {
          margin-bottom: 6%;
        }
      }
    }
  }
}

#payment {
  .body_container {
    .add-payment-page {
      .add-payment-page {
        &__content {
          margin: 0px;
          .breadcrumb {
            display: none;
          }
          &__header {
            font-size: 21px;
          }
          .payment-form {
            &__item {
              select {
                height: calc(2em - -2px);
              }
            }
          }
        }
      }
    }
  }
}

.registration-pro-page {
  .form-submit {
    margin-#{$ldirection}: 20px;
  }
  &__required-container {
    top: 65px;
    position: relative;
  }
}

.newsletter-info {
  &__text {
    clear: #{$ldirection};
    margin-#{$ldirection}: 20px;
  }
}

.pending-pro,
.registration-pro-page {
  &__header {
    margin-bottom: 0;
    &:before {
      bottom: 0;
      top: auto;
    }
  }
  &_info {
    padding: 0 20px;
  }
  .pro-member-info-form {
    margin-#{$ldirection}: 0;
  }
  label {
    &.error {
      color: $color-red;
    }
  }
}

.pro_level_criteria,
.pending-pro_registration_steps {
  ul {
    list-style-type: disc;
    padding-#{$ldirection}: 20px;
  }
}
